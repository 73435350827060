class StarterKitMcube {
	constructor() {
		clickOrTouch = document.documentElement.classList.contains('no-touchevents') ? 'click' : 'touchstart';
		this.madeByMcube();
		this.mcubeDebug();
		mobileCheck = new MobileCheck();
		new SmoothScroll();
		new ResizeEvents();
		new AppearOnScroll();
		new ImageLoaded();
		new DataLink();
		new Forms();
		new Parallax();
		new TextAppear();
		new Tooltips();
	}

	consoleLog($message, $bg, $color, link) {
		console.log('%c' + $message, 'background-color:' + $bg + '; color:' + $color + ';padding:3px 5px;', link);
	}

	madeByMcube() {
		this.consoleLog('Fabriqué à Lyon par Mcube', '#fa2165', '#ffffff', 'www.mcube.fr');
	}

	mcubeDebug() {
		devMode = localStorage.getItem('devMode');
		if (devMode === 'true') {
			document.body.classList.add('debug-mode');
		}
	}
}
