
class PagesTryMe{

	constructor() {

		let pin = document.querySelector('.model-3d-pin');
		if( !pin )
			return;
		let container = document.querySelector('.try-me-container');
		let elDistanceToTop = document.querySelector(".header-main").offsetHeight;

		let mm = gsap.matchMedia();

		mm.add("(min-width: 992px)", () => {

			ScrollTrigger.create({
				trigger: container,
				pin: pin,
				start: "top-=" + elDistanceToTop + " top",
				end: "bottom bottom"
			});

		});

	}

}