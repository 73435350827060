class Parallax {

	/**
	 *
	 * Le système de parallax se base uniquement sur les résolutions d'écran, par sur un userAgent
	 *
	 * Parallax X ou Y :
	 *
	 * Exemple de décalage latéral d'un titre de 50px vers la droite dans toutes les résolutions (px, %, vw ...)
	 * <h1 data-parallax-x="50px">
	 *
	 * Exemple de décalage latéral d'un titre de 50px vers la droite uniquement à partir de la résolution MD (768px)
	 * <h1 data-parallax-x="50px" data-parallax-from="md">
	 *
	 * Parallax scale ou inside :
	 *
	 * Nécessite un parent data-parallax-container
	 *
	 * Exemple de parallax interne de 30% à partir de la résolution MD (768px) :
	 * <div data-parallax-container>
	 *     <img src="..." data-parallax-inside="30" data-parallax-from="md"/>
	 * </div>
	 *
	 */
	constructor() {
		this.sizes = {
			"sm": 576,
			"md": 768,
			"lg": 992,
			"xl": 1200,
			"xxl": 1400,
			"hyper": 1800,
		};
		this.build();
		resizeCallbacks.push(this);
	}

	build() {
		this.matchMedia = gsap.matchMedia();
		this.xParallaxes = document.querySelectorAll("[data-parallax-x]");
		this.yParallaxes = document.querySelectorAll("[data-parallax-y]");
		this.scaleParallaxes = document.querySelectorAll("[data-parallax-scale]");
		this.insideParallaxes = document.querySelectorAll("[data-parallax-inside]");
		this.xParallaxes.forEach(elem => {
			this.parallaxXY(elem, elem.dataset.parallaxX || 50, undefined);
		});
		this.yParallaxes.forEach(elem => {
			this.parallaxXY(elem, undefined, elem.dataset.parallaxY || 50);
		});
		this.scaleParallaxes.forEach(elem => {
			this.scale(elem);
		});
		this.insideParallaxes.forEach(elem => {
			this.inside(elem);
		});
	}

	parallaxXY(elem, x, y) {
		this.matchMedia.add(this.getMediaQuery(elem), () => {
			const start = elem.dataset.parallaxStart || "top bottom";
			const end = elem.dataset.parallaxEnd || "bottom top";
			const triggerStart = elem.dataset.triggerStart || elem;
			const config = {
				ease: "none",
				scrollTrigger: {
					trigger: elem,
					triggerStart: triggerStart,
					start: start,
					end: end,
					scrub: 1
				}
			};
			if (x) {
				config.x = x;
			}
			if (y) {
				config.y = y;
			}
			elem.tween = gsap.to(elem, config);
		});
	}

	scale(elem) {
		elem.parallaxContainer = elem.closest("[data-parallax-container]");
		if (!elem.parallaxContainer) {
			console.log("data-parallax-scale error : parent data-parallax-container is missing", elem);
			return;
		}
		this.matchMedia.add(this.getMediaQuery(elem), () => {
			gsap.set(elem.parallaxContainer, {
				height: `${elem.clientHeight}px`,
				width: `${elem.clientWidth}px`,
				overflow: "hidden"
			});
			elem.tween = gsap.to(elem, {
				scale: elem.dataset.parallaxScale,
				ease: "none",
				scrollTrigger: {
					trigger: elem,
					start: "top bottom",
					end: "bottom top",
					scrub: 1
				}
			});
		});
	}

	inside(elem) {
		elem.parallaxContainer = elem.closest("[data-parallax-container]");
		if (!elem.parallaxContainer) {
			console.log("data-parallax-inside error : parent data-parallax-container is missing", elem);
			return;
		}

		this.matchMedia.add(this.getMediaQuery(elem), () => {
			const ratio = elem.dataset.parallaxInside || 25;
			elem.parallaxContainer.style.width = `${elem.clientWidth}px !important`;
			elem.parallaxContainer.style.height = `${elem.clientHeight}px`;
			elem.parallaxContainer.style.overflow = "hidden";
			elem.style.objectFit = "cover";
			elem.style.transform = `translateY(-${ratio}%)`;
			const previousHeight = elem.clientHeight;
			const newHeight = previousHeight * (ratio / 100 + 1) + "px";
			elem.style.setProperty("height", newHeight, "important");

			elem.tween = gsap.to(elem, {
				y: "0",
				ease: "none",
				scrollTrigger: {
					trigger: elem,
					start: "top bottom",
					end: "bottom top",
					scrub: 1
				}
			});
		});
	}

	resize() {
		this.xParallaxes.forEach(elem => {
			this.clearAll(elem);
			setTimeout(() => {
				this.parallaxXY(elem, elem.dataset.parallaxX || 50, undefined);
			}, 0);
		});

		this.yParallaxes.forEach(elem => {
			this.clearAll(elem);
			setTimeout(() => {
				this.parallaxXY(elem, undefined, elem.dataset.parallaxY || 50);
			}, 0);
		});

		this.scaleParallaxes.forEach(elem => {
			this.clearAll(elem);
			this.clearAll(elem.parallaxContainer);
			setTimeout(() => {
				this.scale(elem);
			}, 0);
		});

		this.insideParallaxes.forEach(elem => {
			this.clearAll(elem);
			this.clearAll(elem.parallaxContainer);
			setTimeout(() => {
				this.inside(elem);
			}, 0);
		});
	}

	getMediaQuery(elem) {
		let size = "1";
		if (elem.dataset.parallaxFrom) {
			if (this.sizes[elem.dataset.parallaxFrom]) {
				size = this.sizes[elem.dataset.parallaxFrom];
			}
		}
		return `(min-width: ${size}px)`;
	}

	clearAll(elem) {
		if (elem.tween) {
			if (elem.tween.scrollTrigger) {
				elem.tween.scrollTrigger.kill();
			}
			elem.tween.kill();
		}
		gsap.set(elem, {clearProps: "all"});
	}
}
