
class MouseParallax {

    constructor() {

        let mouseParallax = document.querySelectorAll('.mouse-parallax');
        mouseParallax.forEach( (parallax) => {

            let elements = parallax.querySelectorAll('.mouse-parallax-element');

            parallax.addEventListener('mousemove', (event) => {

                elements.forEach( (element) => {
                    gsap.to(element, 1, {
                        x: (event.pageX - parallax.offsetWidth / 2) / parallax.offsetWidth * 70,
                        y: (event.pageY - parallax.offsetHeight / 2) / parallax.offsetHeight * 70,
                    });
                });
            });

        });



    }
}
