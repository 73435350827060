class BlockListCta{

	constructor() {

		let blocks = document.querySelectorAll('.block-list-cta');

		blocks.forEach( (block) => {
			let buttons = block.querySelectorAll('.block-list-cta-btn');
			buttons.forEach( (btn) => {
				btn.addEventListener('click', (e) => {
					let movie = e.target.dataset.movie;
					let modal = document.getElementById('blockListCta');
					this.player = document.createElement('video');
					modal.addEventListener('shown.bs.modal', (event) => {
						this.player.src = movie;
						this.player.autoplay = true;
						this.player.controls = true;
						modal.querySelector('.modal-body').append(this.player);
					});
					modal.addEventListener('hide.bs.modal', (event) => {
						this.player.remove();
					});
				});
			});
		});

	}

}