
class LoadScript{
    static load(script, callback){

        const findScript = document.querySelector("script[data-loadscript][src='" + script + "']");

        if (findScript) {

            callback();

        } else {

            const loadingScript = document.createElement('script');
            loadingScript.setAttribute('src', script);
            loadingScript.setAttribute('data-loadscript', 'true');
            loadingScript.async = true;
            document.body.appendChild(loadingScript);

            loadingScript.addEventListener('load', function () {
                callback();
            });

        }

    }
}