class Buttons{

	constructor() {

		let buttons = document.querySelectorAll('.btn');

		buttons.forEach( (button) => {
			let ball = document.createElement('span');
			ball.classList.add('ball');
			button.appendChild(ball);

			button.addEventListener("mousemove", (e) => {
				const { left, top, width, height } = ball.getBoundingClientRect();

				const halfW = width;
				const halfH = height / 2;
				const mouseX = e.x - left;
				const mouseY = e.y - top;

				const x = gsap.utils.interpolate(-halfW, halfW, mouseX / width);
				const y = gsap.utils.interpolate(-halfH, halfH, mouseY / height);

				gsap.to(ball, {
					x: (mouseX / width ) * 5,
					y: ( mouseY / height ) * 5,
					duration: 0.6,
					ease: "power1",
					overwrite: true
				});
			});
			button.addEventListener("mouseleave", () => {
				gsap.to(ball, {
					x: 0,
					y: 0,
					ease: 'elastic.out(1.1, .8)',
					duration: 0.8,
					overwrite: true
				});
			});
		});

	}

}