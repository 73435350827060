class Follower{

	constructor() {

		let followers = document.querySelectorAll('.follower-gradient');
		let bannerVideo = document.querySelector('.block-banner-movie');

		followers.forEach( (follower) => {
			document.body.addEventListener('mousemove', (e) => {
				TweenLite.to(follower, 0.3, {
					css: {
						left: e.pageX,
						top: e.pageY
					}
				});

				if( bannerVideo ){

					ScrollTrigger.create({
						'trigger' : bannerVideo,
						'start' : 0,
						'end' : 'bottom top',
						toggleClass: {
							targets: followers,
							className: "disabled"
						}
					});

				}

			});
		});

	}

}