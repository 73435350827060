class BlockMovie{

	constructor() {

		let movies = document.querySelectorAll('.block-movie');

		movies.forEach( (movie) => {

			movie.querySelector('.movie-container').addEventListener("click", () => {
				movie.classList.toggle('is-playing');
				let video = movie.querySelector('video.full');
				if (video.paused) {
					video.controls = true;
					video.play();
				}else {
					video.controls = false;
					video.pause();
				}
			});

		});
	}

}