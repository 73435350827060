class BlockBanner{

	constructor() {

		let banners = document.querySelectorAll('.block-banner');

		banners.forEach( (banner) => {

			let gradient = banner.querySelector('.gradient');
			banner.addEventListener('mousemove', (e) => {
				TweenLite.to(gradient, 0.3, {
					css: {
						left: e.pageX,
						top: e.pageY
					}
				});
			});

			let title = banner.querySelector('.title');
			new SplitText(title, {
				type: "words,chars",
				charsClass: 'parent'
			});
			const splitTextChild = new SplitText(title, {
				type: "chars",
				charsClass: 'child'
			});

			let subtitle = banner.querySelector('.subtitle');
			new SplitText(subtitle, {
				type: "lines",
				linesClass: 'parent'
			});
			const splitSubtitleChild = new SplitText(subtitle, {
				type: "chars",
				charsClass: 'child'
			});

			const tl = gsap.timeline();
			tl.fromTo(splitTextChild.chars, {
				xPercent: -100,
				opacity: 0
			},
			{
				duration: 0.3,
				immediateRender: true,
				ease: Power4.inOut,
				xPercent: 0,
				opacity: 1,
				stagger: 0.04
			});
			tl.fromTo(splitSubtitleChild.chars, {
				yPercent: 100,
				opacity: 0
			},
			{
				duration: 0.3,
				immediateRender: true,
				ease: Power4.inOut,
				yPercent: 0,
				opacity: 1,
				stagger: 0.02
			}, 0.3);

		});
	}

}