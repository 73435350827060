class MatchHeight {
	constructor() {
		$.fn.matchHeight._applyDataApi();
		document.addEventListener('lazyloaded', function (e) {
			this.resize();
		}.bind(this));
		resizeCallbacks.push(this);
	}

	resize() {
		this.destroy();
		$.fn.matchHeight._maintainScroll = true;
		$.fn.matchHeight._applyDataApi();
	}

	destroy() {
		$('[data-mh]').matchHeight({remove: true});
	}

}
