class BlockScrollingContent{

	constructor() {

		let blocks = document.querySelectorAll('.block-scrolling-content');
		this.matchMedia = gsap.matchMedia();

		blocks.forEach( (block) => {

			this.matchMedia.add("(min-width: 768px)", () => {
				let pinElement = block.querySelector('.pin-element');
				let content = block.querySelector('.scrolling-content');
				let panes = block.querySelectorAll('.scrolling-pane');

				ScrollTrigger.create({
					pin: pinElement,
					start: "top center",
					endTrigger : panes[panes.length- 1],
					end: "top center"
				});

				panes.forEach( (pane) => {
					gsap.to( pane, {
						opacity : 1,
						scrollTrigger: {
							trigger: pane,
							start: "top bottom",
							end: "bottom center",
							scrub: true
						}
					});
				});
			});

		});
	}

}