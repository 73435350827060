class BlockSliderCta{

	constructor() {

		let blocks = document.querySelectorAll('.block-slider-cta');

		blocks.forEach( (block) => {

			let slider = block.querySelector(' .swiper');
			let pagination = block.querySelector(' .block-slider-navigation');

			new Swiper(slider, {
				navigation: {
					nextEl: pagination.querySelector('.block-slider-next'),
					prevEl: pagination.querySelector('.block-slider-prev')
				}
			});

		});
	}

}