
class Navigation{

	constructor() {

		this.headerContainer = document.querySelector('.header-main');

		if( !this.headerContainer )
			return;

		resizeCallbacks.push(this);

		Observer.create({
			type: "scroll",
			tolerance: 100,
			dragMinimum: 100,
			onStopDelay: 0,
			onUp: () => {
				this.showHeader();
			},
			onDown: () => {
				if( !document.body.classList.contains("main-nav-open") ) {
					this.hideHeader();
				}
			}
		});

		this.toggleMenu = document.querySelector('.toggle');

		if( !this.toggleMenu ){
			return;
		}

		this.toggleMenu.addEventListener('click', () => {
			document.body.classList.toggle('main-nav-open', !document.body.classList.contains('main-nav-open'));
			smoother.paused( document.body.classList.contains('main-nav-open') );
		});

		document.addEventListener("click", function(e){
			if( !e.target.closest('main-navigation') && !e.target.classList.contains('main-navigation') && !e.target.classList.contains('toggle') && document.body.classList.contains('main-nav-open') ) {
				document.body.classList.toggle('main-nav-open', false);
				smoother.paused( document.body.classList.contains('main-nav-open') );
			}
		});

		this.mainNavigation = document.querySelector('.main-navigation');
		this.plus = this.mainNavigation.querySelector('.svg');
		this.links = this.mainNavigation.querySelectorAll('a:not(.btn)');
		this.current = this.mainNavigation.querySelector('a.current');

		this.setCurrentLink();

		this.links.forEach( (link) => {
			link.addEventListener('mouseenter', (e) => {
				let rect = link.getBoundingClientRect();
				this.containerRect = this.mainNavigation.getBoundingClientRect();
				let relX = rect.x - this.containerRect.x - this.plus.offsetWidth * 2;
				let relY = rect.y - this.containerRect.y;
				gsap.fromTo( this.plus, 0.5,{
					rotate : 0,
					overwrite: true
				},{
					y : relY,
					x : relX,
					rotate : "+=180deg",
					overwrite: true
				});
			});
			link.addEventListener('mouseleave', (e) => {
				gsap.to( this.plus, 0.5,{
					rotate : 0,
					x : this.relCurrentX,
					y : this.relCurrentY,
					overwrite: true
				});
			});
		});

	}

	hideHeader() {
		gsap.to(this.headerContainer, 0.3, {
			yPercent : -100,
			ease : "none"
		});
	}

	showHeader() {
		gsap.to(this.headerContainer, 0.3, {
			yPercent : 0,
			ease : "none"
		});
	}

	setCurrentLink(){
		if( this.current ) {
			this.containerRect = this.mainNavigation.getBoundingClientRect();
			let rect = this.current.getBoundingClientRect();
			this.relCurrentX = rect.x - this.containerRect.x - this.plus.offsetWidth * 2;
			this.relCurrentY = rect.y - this.containerRect.y;
			gsap.set(this.plus, {
				x: this.relCurrentX,
				y: this.relCurrentY
			});
		}
	}

	resize(){
		this.setCurrentLink();
	}

}