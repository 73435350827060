class AppearOnScroll {
	constructor() {
		const items = document.querySelectorAll('[data-aos]');

		items.forEach(elem => {
			const delay = elem.dataset.aosDelay;
			ScrollTrigger.create({
				trigger: elem,
				start: 'top bottom',
				once: true,
				onEnter: function () {
					if (delay) {
						setTimeout(function () {
							elem.classList.add('appeared');
						}, parseInt(delay));
					} else {
						elem.classList.add('appeared');
					}
				}
			});
		});
	}
}