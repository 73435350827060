class Forms {
	constructor() {
		this.forms = document.querySelectorAll("form");

		if (this.forms) {
			this.initInputEvents();
		}
	}

	initInputEvents() {
		const inputs = document.querySelectorAll(".form-field:not(.type-checkbox):not(.type-radio):not(.type-file) input, .form-field textarea, .form-field select");
		inputs.forEach(input => {
			const inputContainer = input.closest(".form-field");

			input.addEventListener("focus", () => {
				inputContainer.classList.add("active");
			});

			input.addEventListener("blur", () => {
				inputContainer.classList.remove("active");

				if (input.value === "") {
					inputContainer.classList.remove("filled");
				} else {
					inputContainer.classList.add("filled");
				}
			});
		});
	}
}
