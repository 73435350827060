let devMode,
	mobileCheck = false,
	resizeCallbacks = [],
	clickOrTouch,
	mobileResizeCallbacks = [],
	smoother;

document.addEventListener("DOMContentLoaded", function(event) {
	// your page initialization code here
	// the DOM will be available here
	new StarterKitMcube();
	new AppearOnScroll();
	new TextAppear();
	// Composants Mobile & Desktop

	new Navigation();
	new ModalVideo();

	new BlockMovie();
	new BlockScrollingContent();
	// new BlockListCta();
	new BlockSliderCta();

	new PagesTryMe();

	new Buttons();
	new Follower();
	new MouseParallax();
	// new Animation3d();

});
