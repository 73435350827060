class DataLink {
	constructor() {
		const that = this;
		this.items = document.querySelectorAll('[data-link]');
		this.items.forEach(item => {
			item.addEventListener("click", e => {this.bindEvent(e, item);});
			item.addEventListener("auxclick", e => {this.bindEvent(e, item);});
		});
	}

	bindEvent(e,item) {
		const url = item.getAttribute("href") || item.dataset.link;
		const target = item.getAttribute("target");
		const link = document.createElement('a');
		link.href = url;

		e.preventDefault();
		e.stopPropagation();

		if (e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button === 1)) {
			link.target = '_blank';

		} else if (target) {
			link.target = target;
		}

		document.body.appendChild(link);
		link.click();
		link.remove();
	}
}