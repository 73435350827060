class ImageLoaded{

	constructor() {

		let pictureFrameImage = document.querySelectorAll('.picture-frame-image');

		pictureFrameImage.forEach( (picture) => {
			ScrollTrigger.create({
				trigger: picture,
				start: 'top bottom',
				once: true,
				onEnter: function () {
					picture.classList.add('appeared');
				}
			});
		});

	}

}