class ResizeEvents {
	constructor() {

		var timer,
			orientationchanged = true,
			newOrientation,
			oldOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

		window.addEventListener("resize", function () {
			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(function () {
				if (mobileCheck.isMobile) {
					newOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
					orientationchanged = newOrientation !== oldOrientation;
					oldOrientation = newOrientation;
					if (!orientationchanged) {
						window.dispatchEvent(new Event('mobileResize'));
					}
				}

				if (orientationchanged) {
					window.dispatchEvent(new Event('customResize'));
				}
			}, 100);
		});

		window.addEventListener('customResize', function () {
			resizeCallbacks.forEach(function (callback) {
				try {
					callback.resize();
				} catch (error) {
					console.log('Starterkit : Missing resize function in object ' + callback.constructor.name);
				}
			});
		});

		window.addEventListener('mobileResize', function () {
			mobileResizeCallbacks.forEach(function (callback) {
				try {
					callback.resize();
				} catch (error) {
					console.log('Starterkit : Missing resize function in object ' + callback.constructor.name);
				}
			});
		});
	}
}